import React, { useMemo } from "react"
import { graphql } from "gatsby"
import qs from 'qs'
import Layout from "../components/layout"
import Seo from "../components/seo"
import Lanci from "../components/lanci"
import Breadcrumb from "../components/elements/breadcrumb"
import pageUrl from "../utility/page-url"
import Carousel from "../components/carousel"
import withPreview from '../utility/with-preview'


const mediaFilters = [{
  name: `Tutti`,
  value: 'all',
  icon: `list`
}, {
  name: `News`,
  value: 'news',
  icon: `envelope`
}, {
  name: `Eventi`,
  value: 'event',
  icon: `map`
}, {
  name: `Comunicati Stampa`,
  value: 'press',
  icon: `document`
}];

const educationalFilters = [{
  name: `Tutti`,
  value: 'all_educational',
  icon: `list`
}, {
  name: `Cultura`,
  value: 'cultura',
  icon: `expert`
}, {
  name: `Educazione`,
  value: 'educazione',
  icon: `houses`
}, {
  name: `Sensibilizzazione`,
  value: 'sensibilizzazione',
  icon: `nature`
}];

const CarouselEditable = withPreview(Carousel);

const NewsroomPage = ({ pageContext: { langCode }, data: { meta, page, pagineNewsroom }, location }) => {
  const { htmlTitle, mainTitle, mainSubtitle, tileCategory, tileType, tileList, 
    highlightNumber, rowsNumber, correlated } = page;
  const lanciConfig = { highlightNumber, rowsNumber };
  const filtro = tileType.value[0];
  const category = (tileCategory && tileCategory.value[0]) || "media";

  const query = qs.parse(location.search ? location.search.slice(1) : '');
  const { loaded = rowsNumber.value } = query;

  const filters = useMemo(() => {
    const pageNodes = pagineNewsroom.edges.map(edge => edge.node);
    if (category === 'media' && filtro === 'publication') return [];
    return (category === 'media' ? mediaFilters : educationalFilters).reduce((list, filter) => {
      const pageNode = pageNodes.find(node => node.tileType.value[0] === filter.value);
      return pageNode ? list.concat({ ...filter, path: `/${pageUrl(pageNode, true)}#filters` }) : list;
    }, []);
  }, [pagineNewsroom, category, filtro]);
  const lanciTileList = useMemo(() => {
    return { edges: tileList.map(tile => ({ node: tile }))}
  }, [tileList]);

  const canonical = (htmlTitle.content && htmlTitle.content.canonicalUrl && htmlTitle.content.canonicalUrl.value) 
    || `${meta.siteUrl}/${pageUrl(page)}`;
  
  return (
    <Layout langCode={langCode} location={location}>
      <Seo title={htmlTitle.value || page.pageTitle.value} langCode={langCode} ogImage={htmlTitle.content.ogImage}
        canonical={canonical}
        description={htmlTitle.content.metaDescription && htmlTitle.content.metaDescription.value} />
      <div className="newsroom">
        <div className="newsroom__intro d-flex flex-column justify-content-center pb-7">
          <div className="container">
            {/* <Breadcrumb breadcrumb={breadcrumb.concat([{ label: pageTitle.value }])} className="breadcrumb--dark" /> */}
            <Breadcrumb page={page} className="breadcrumb--dark" />
            <div className="row">
              <div className="col-12 pt-6">
                <h1 className="hero-icons__title mb-2" dangerouslySetInnerHTML={{__html: mainTitle.value }}></h1>
              </div>
            </div>
            {mainSubtitle && mainSubtitle.value ? (
              <div className="row">
                <div className="col-lg-5">
                  <div className="newsroom__subtitle pb-4" dangerouslySetInnerHTML={{__html: mainSubtitle.value }}>
                  </div>
                </div>
              </div>
            ): null}
          </div>
        </div>
        <Lanci data={lanciConfig} tileList={lanciTileList} filters={filters} currentFilter={filtro} hasLoadMore={true}
          intro={filtro === 'publication' ? '': 'Ultimi articoli:'} 
          highlightField="highlightNews" loadedRows={loaded} />
        {correlated ? (
          <CarouselEditable data={correlated} />
        ): null}
      </div>
    </Layout>
  );
}

export default withPreview(NewsroomPage, { subField: 'page', fixed: true });
export const query = graphql`query NewsroomQuery($liferayId: Int!, $langCode: String!){
  meta: gatsbySourceLiferayOptions {
    siteUrl
  }
  page: liferayPaginanewsroom(liferayId: { eq: $liferayId }, langCode: { eq: $langCode }) {
    siteId
    articleId
    langCode
    pageTitle {
      value
      content {
        friendlyUrl {
          value
        }
      }
    }
    htmlTitle {
      value
      content {
        metaDescription {
          value
        }
        canonicalUrl {
          value
        }
        ogImage {
          node {
            resize(width: 1200) {
              src
              width
              height
            }
          }
        }
      }
    }
    mainTitle {
      value
    }
    mainSubtitle {
      value
    }
    tileCategory {
      value
    }
    tileType {
      value
    }
    tileList {
      ...TileFragment
    }
    highlightNumber {
      value
    }
    rowsNumber {
      value
    }
    correlated {
      ...ProdotticorrelatiFragment
    }
    ...BreadcrumbPaginanewsroomFragment
  }
  pagineNewsroom: allLiferayPaginanewsroom(filter: {langCode: {eq: $langCode}}) {
    edges {
      node {
        langCode
        pageTitle {
          value
          content {
            friendlyUrl {
              value
            }
          }
        }
        tileType {
          value
        }
      }
    }
  }
}`

